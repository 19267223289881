/**
 * Copyright (C) 2020 Andrew Rioux, Melissa Trick
 *
 * This file is part of Event Manager.
 *
 * Event Manager is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 2 of the License, or
 * (at your option) any later version.
 *
 * Event Manager is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with Event Manager.  If not, see <http://www.gnu.org/licenses/>.
 */

import Page, { PageProps } from '../Page';
import React, { FunctionComponent } from 'react';
import styles from './quizzer.module.css';
import { ribbonURLs, insigniaURLs, InsigniaKeys, RibbonKeys } from './images';
import Button from '../../components/Button';

/**
 * Returns a random number from 0 to the number specified
 */
function getRandomNumber(upto: number): number {
	return Math.floor(Math.random() * upto);
}

/**
 * Randomly returns true or false
 */
function getRandomBoolean(): boolean {
	return getRandomNumber(2) === 1;
}

/**
 * Given an enum, returns a function which generates random values from the enum
 * The enum has to be one generated by TypeScript, as they have a specific structure
 */
const getRandomEnum = <T extends any>(value: any) => (): T =>
	(getRandomNumber(Object.entries(value).length / 2) as unknown) as T;

/**
 * Represents the grade of a cadet the quiz is based off of
 */
// Taken from here https://www.gocivilairpatrol.com/media/cms/CAPVA_60100_1CAF4A9C48C91.pdf
enum GradeLevel {
	LINDBERGH,
	DOOLITTLE,
	GODDARD,
	ARMSTRONG,
}
interface Grade {
	isFirstSergeant: boolean;

	level: GradeLevel;
}
const getRandomGrade = (): Grade => ({
	level: getRandomEnum<GradeLevel>(GradeLevel)(),
	isFirstSergeant: getRandomBoolean(),
});
const getGradeString = (grade: Grade): string =>
	({
		[GradeLevel.LINDBERGH]: grade.isFirstSergeant
			? 'This cadet is a Cadet Master Sergeant.'
			: 'This cadet is a Cadet Master Sergeant, and serves as the First Sergeant at their squadron.',
		[GradeLevel.DOOLITTLE]: grade.isFirstSergeant
			? 'This cadet is a Cadet Senior Master Sergeant.'
			: 'This cadet is a Cadet Senior Master Sergeant, and serves as the First Sergeant at their squadron.',
		[GradeLevel.GODDARD]: grade.isFirstSergeant
			? 'This cadet is a Cadet Chief Master Sergeant.'
			: 'This cadet is a Cadet Chief Master Sergeant, and serves as the First Sergeant at their squadron.',
		[GradeLevel.ARMSTRONG]: grade.isFirstSergeant
			? 'This cadet is a Chief Master Sergeant and has the Armstrong Achievement.'
			: 'This cadet is a Chief Master Sergeant and has the Armstrong Achievement, and serves as the First Sergeant at their squadron.',
	}[grade.level]);

/**
 * Represents at what level in the cadet advisory council the cadet sits at
 */
enum CACLevel {
	NATIONAL,
	REGION,
	WING,
	GROUP,
	NONE,
}
const getRandomCACLevel = getRandomEnum<CACLevel>(CACLevel);
const getCACLevelString = (level: CACLevel): string =>
	({
		[CACLevel.NONE]: 'This cadet has not served on CAC.',
		[CACLevel.GROUP]: 'This cadet has served on the group CAC.',
		[CACLevel.WING]: 'This cadet has served on the wing CAC.',
		[CACLevel.REGION]: 'This cadet has served on the regional CAC.',
		[CACLevel.NATIONAL]: 'This cadet has served on the national CAC.',
	}[level]);

/**
 * Represents at what level the cadet participated in the cadet competition
 */
enum ColorGuardLevel {
	NATIONAL,
	REGION,
	WING,
	SQUADRON,
	NONE,
}
const getRandomColorGuardLevel = getRandomEnum<ColorGuardLevel>(ColorGuardLevel);
const getColorGuardString = (level: ColorGuardLevel): string =>
	({
		[ColorGuardLevel.NONE]: 'This cadet has not served on a color guard.',
		[ColorGuardLevel.SQUADRON]:
			'This cadet has served on the squadron color guard, but not competed in the cadet competition.',
		[ColorGuardLevel.WING]:
			'This cadet has served on a color guard in the Wing Cadet Competition.',
		[ColorGuardLevel.REGION]:
			'This cadet has served on a color guard in the Region Cadet Competition.',
		[ColorGuardLevel.NATIONAL]:
			'This cadet has served on a color guard in the National Cadet Competition.',
	}[level]);

/**
 * Determines how many hours of community service the cadet in question has
 */
enum CommunityServiceLevel {
	HOURS20,
	HOURS40,
	HOURS60,
	HOURS80,
	NONE,
}
const getRandomCommunityServiceLevel = getRandomEnum<CommunityServiceLevel>(CommunityServiceLevel);
const getCommunityServiceString = (service: CommunityServiceLevel): string | undefined =>
	({
		[CommunityServiceLevel.HOURS20]:
			'This cadet has 20 hours of community service outside of CAP.',
		[CommunityServiceLevel.HOURS40]:
			'This cadet has 40 hours of community service outside of CAP.',
		[CommunityServiceLevel.HOURS60]:
			'This cadet has 60 hours of community service outside of CAP.',
		[CommunityServiceLevel.HOURS80]:
			'This cadet has 80 hours of community service outside of CAP.',
		[CommunityServiceLevel.NONE]: undefined,
	}[service]);

/**
 * Determines if the cadet has earned an air Search and Rescue ribbon, and if so how
 */
enum AirSAR {
	SORTIES5,
	SORTIES10,
	SORTIES20,
	SORTIES30,
	NONE,
}
const getRandomAirSAR = getRandomEnum<AirSAR>(AirSAR);
const getAirSARString = (airSar: AirSAR): string | undefined =>
	({
		[AirSAR.SORTIES5]: 'This cadet has participated in 5 air search and rescue sorties.',
		[AirSAR.SORTIES10]: 'This cadet has participated in 10 air search and rescue sorties.',
		[AirSAR.SORTIES20]: 'This cadet has participated in 20 air search and rescue sorties.',
		[AirSAR.SORTIES30]: 'This cadet has participated in 30 air search and rescue sorties.',
		[AirSAR.NONE]: undefined,
	}[airSar]);

/**
 * Represents the activities the cadet participated in
 */
type ActivityAttendance = { type: 'ATTENDED_ENCAMPMENT'; ncsas: number } | { type: 'NONE' };
const getRandomActivityAttendance = (): ActivityAttendance =>
	getRandomBoolean()
		? { type: 'ATTENDED_ENCAMPMENT', ncsas: getRandomNumber(5) }
		: { type: 'NONE' };
const getActivityAttendanceString = (attendance: ActivityAttendance): string | undefined =>
	attendance.type === 'NONE'
		? undefined
		: attendance.ncsas === 0
		? 'This cadet has attended Encampment.'
		: `This cadet has attended ${attendance.ncsas} National activit${
				attendance.ncsas === 1 ? 'y' : 'ies'
		  }.`;

/**
 * Stores the quiz that a cadet will take
 */
interface Quiz {
	grade: Grade;
	cadetsRecruited: number;
	yearsInCAP: number;
	cac: CACLevel;
	colorGuardCompetition: ColorGuardLevel;
	communityService: CommunityServiceLevel;
	airSar: AirSAR;
	activityAttendance: ActivityAttendance;

	silverMedalOfValor: boolean;
	bronzeMedalOfValor: boolean;
	distinguishedService: boolean;
	exceptionalService: boolean;
	meritoriousService: boolean;
	commandersCommendation: boolean;
	capAchievement: boolean;
	natCommanderUnitCitation: boolean;
	lifesavingAward: boolean;
	unitCitation: boolean;
	afsaSquadronNCOofTheYear: boolean;
	vfwNCOofTheYear: boolean;
	disasterRelief: boolean;
	findRibbon: boolean;
	afaRibbon: boolean;
}

export interface QuizInput {
	curryChecked: boolean; // done
	arnoldChecked: boolean; // done
	feikChecked: boolean; // done
	wrightBrothersChecked: boolean; // done
	rickenbackerChecked: boolean; // done
	lindbergChecked: boolean; // done
	doolittleChecked: boolean; // done
	goddardChecked: boolean; // done
	armstrongChecked: boolean; // done
	AFOEAChecked: boolean; // done
	encampmentChecked: boolean; // done
	nCSAsChecked: boolean; // done
	recruitChecked: boolean; // done
	cACChecked: boolean; // done
	colorGuardChecked: boolean; // done
	communityServiceChecked: boolean; // done
	airSARChecked: boolean; // done
	findChecked: boolean; // done
	disasterReliefChecked: boolean; // done
	vFW_NCOChecked: boolean; // done
	aFSAChecked: boolean; // done
	unit_CitationChecked: boolean; // done
	lifesavingChecked: boolean; // done
	national_Unit_CitationChecked: boolean; // done
	cAP_AchievementChecked: boolean; // done
	commanders_CommendationChecked: boolean; // done
	meritorious_serviceChecked: boolean; // done
	exceptional_serviceChecked: boolean; // done
	distinguished_serviceChecked: boolean; // done
	bronze_MVChecked: boolean; // done
	silver_MVChecked: boolean; // done
	// nationalCACChecked: boolean;
	// regionCACChecked: boolean;
	// wingCACChecked: boolean;
	iaceChecked: boolean; // done
	vfwOfficer: boolean; // done
	afaChecked: boolean; // done
	redService: boolean; // done

	grade: Grade | null;
}

function generateQuiz(): Quiz {
	return {
		grade: getRandomGrade(),
		cadetsRecruited: getRandomNumber(5),
		yearsInCAP: getRandomNumber(5) + 1,
		cac: getRandomCACLevel(),
		colorGuardCompetition: getRandomColorGuardLevel(),
		communityService: getRandomCommunityServiceLevel(),
		airSar: getRandomAirSAR(),
		activityAttendance: getRandomActivityAttendance(),

		silverMedalOfValor: getRandomBoolean(),
		bronzeMedalOfValor: getRandomBoolean(),
		distinguishedService: getRandomBoolean(),
		exceptionalService: getRandomBoolean(),
		meritoriousService: getRandomBoolean(),
		commandersCommendation: getRandomBoolean(),
		capAchievement: getRandomBoolean(),
		natCommanderUnitCitation: getRandomBoolean(),
		lifesavingAward: getRandomBoolean(),
		unitCitation: getRandomBoolean(),
		afsaSquadronNCOofTheYear: getRandomBoolean(),
		vfwNCOofTheYear: getRandomBoolean(),
		disasterRelief: getRandomBoolean(),
		findRibbon: getRandomBoolean(),
		afaRibbon: getRandomBoolean(),
	};
}

function getEmptyQuizInput(): QuizInput {
	return {
		curryChecked: false,
		arnoldChecked: false,
		feikChecked: false,
		wrightBrothersChecked: false,
		rickenbackerChecked: false,
		lindbergChecked: false,
		doolittleChecked: false,
		goddardChecked: false,
		armstrongChecked: false,
		AFOEAChecked: false,
		encampmentChecked: false,
		nCSAsChecked: false,
		recruitChecked: false,
		cACChecked: false,
		colorGuardChecked: false,
		communityServiceChecked: false,
		airSARChecked: false,
		findChecked: false,
		disasterReliefChecked: false,
		vFW_NCOChecked: false,
		aFSAChecked: false,
		unit_CitationChecked: false,
		lifesavingChecked: false,
		national_Unit_CitationChecked: false,
		cAP_AchievementChecked: false,
		commanders_CommendationChecked: false,
		meritorious_serviceChecked: false,
		exceptional_serviceChecked: false,
		distinguished_serviceChecked: false,
		bronze_MVChecked: false,
		silver_MVChecked: false,
		// nationalCACChecked: false,
		// regionCACChecked: false,
		// wingCACChecked: false,
		iaceChecked: false,
		vfwOfficer: false,
		afaChecked: false,
		redService: false,

		grade: null,
	};
}

function getErrorsForQuizInput(quiz: Quiz, input: QuizInput): string[] {
	const baseRibbonsNotChecked =
		!input.curryChecked ||
		!input.arnoldChecked ||
		!input.feikChecked ||
		!input.wrightBrothersChecked ||
		!input.rickenbackerChecked ||
		!input.lindbergChecked;

	const thisMonth = new Date().getMonth();
	const monthJoined = thisMonth; // Has to be < 7
	const thisYear = new Date().getFullYear();
	const yearJoined = thisYear - quiz.yearsInCAP;
	const hasAfoeaRibbon = yearJoined === 2016 ? monthJoined < 7 : yearJoined < 2016;

	return [
		!(quiz.grade.level === GradeLevel.ARMSTRONG && input.grade?.level === GradeLevel.GODDARD) &&
			quiz.grade.level !== input.grade?.level &&
			`The grade selected does not match the grade the cadet has`,
		quiz.grade.level === GradeLevel.LINDBERGH &&
			baseRibbonsNotChecked &&
			'The cadet is a C/MSgt, and does not have all the achievement ribbons up to achievement 6',
		quiz.grade.level === GradeLevel.LINDBERGH &&
			baseRibbonsNotChecked &&
			(input.doolittleChecked || input.goddardChecked || input.armstrongChecked) &&
			'This cadet is a C/MSgt. The Doolittle, Goddard, and Armstrong should not be checked.',
		quiz.grade.level === GradeLevel.DOOLITTLE &&
			(baseRibbonsNotChecked || !input.doolittleChecked) &&
			'The cadet is a C/SMSgt, and does not have all the achievement ribbons up to achievement 7',
		quiz.grade.level === GradeLevel.DOOLITTLE &&
			baseRibbonsNotChecked &&
			(input.goddardChecked || input.armstrongChecked) &&
			'This cadet is a C/SMSgt. The Goddard and Armstrong should not be checked.',
		quiz.grade.level === GradeLevel.GODDARD &&
			(baseRibbonsNotChecked || !input.doolittleChecked || !input.goddardChecked) &&
			'The cadet is a C/CMSgt, and does not have all the achievement ribbons up to achievement 8',
		quiz.grade.level === GradeLevel.ARMSTRONG &&
			(baseRibbonsNotChecked ||
				!input.doolittleChecked ||
				!input.goddardChecked ||
				!input.armstrongChecked) &&
			'The cadet is a C/CMSgt with the Armstrong achievement, and does not have all the achievement ribbons up to achievement 9',
		quiz.grade.isFirstSergeant === input.grade?.isFirstSergeant &&
			`The cadet ${!quiz.grade.isFirstSergeant ? 'is' : 'is not'} a first sergeant`,
		quiz.cadetsRecruited >= 2 &&
			!input.recruitChecked &&
			`The cadet has recruited more than 2 other cadets, the recruiter ribbon has to be checked`,
		quiz.cadetsRecruited < 2 &&
			input.recruitChecked &&
			`The cadet has recruited less than 2 other cadets, the recruiter ribbon has to not be checked`,
		quiz.yearsInCAP >= 2 &&
			!input.redService &&
			`The cadet has been in CAP more than 2 years, the red service ribbon must be checked`,
		quiz.yearsInCAP < 2 &&
			input.redService &&
			`The cadet has not been in CAP more than 2 years, the red service ribbon must not be checked`,
		(quiz.colorGuardCompetition === ColorGuardLevel.NONE ||
			quiz.colorGuardCompetition === ColorGuardLevel.SQUADRON) &&
			input.colorGuardChecked &&
			`The cadet has not competed at the wing level or higher, the color guard ribbon must not be checked`,
		(quiz.colorGuardCompetition === ColorGuardLevel.WING ||
			quiz.colorGuardCompetition === ColorGuardLevel.REGION ||
			quiz.colorGuardCompetition === ColorGuardLevel.NATIONAL) &&
			!input.colorGuardChecked &&
			`The cadet has competed at the wing level or higher, the color guard ribbon must be checked`,
		input.iaceChecked &&
			`The cadet must be a cadet captain or higher in order to go on IACE, the IACE ribbon must not be checked`,
		quiz.silverMedalOfValor &&
			!input.silver_MVChecked &&
			`The cadet has recieved the Silver Medal of Valor`,
		!quiz.silverMedalOfValor &&
			input.silver_MVChecked &&
			`The cadet has not recieved the Silver Medal of Valor`,
		quiz.bronzeMedalOfValor === true &&
			!input.bronze_MVChecked &&
			`The cadet has recieved the Bronze Medal of Valor`,
		quiz.bronzeMedalOfValor === false &&
			input.bronze_MVChecked &&
			`The cadet has not recieved the Bronze Medal of Valor`,
		(quiz.communityService === CommunityServiceLevel.NONE ||
			quiz.communityService === CommunityServiceLevel.HOURS20 ||
			quiz.communityService === CommunityServiceLevel.HOURS40) &&
			input.communityServiceChecked &&
			`The cadet has not done enough service outside of CAP to recieve the community service ribbon`,
		(quiz.communityService === CommunityServiceLevel.HOURS60 ||
			quiz.communityService === CommunityServiceLevel.HOURS80) &&
			!input.communityServiceChecked &&
			`The cadet has done enough service outside of CAP to recieve the community service ribbon`,
		(quiz.cac === CACLevel.NONE || quiz.cac === CACLevel.GROUP) &&
			input.cACChecked &&
			`The cadet has not recieved the cac ribbon, the cac ribbon must not be checked`,
		(quiz.cac === CACLevel.REGION ||
			quiz.cac === CACLevel.WING ||
			quiz.cac === CACLevel.NATIONAL) &&
			!input.cACChecked &&
			`The cadet has recieved the cac ribbon, the cac ribbon must be checked`,
		(quiz.airSar === AirSAR.NONE || quiz.airSar === AirSAR.SORTIES5) &&
			input.airSARChecked &&
			`The cadet has not done at least 10 sorties, the air sar ribbon must not be checked`,
		(quiz.airSar === AirSAR.SORTIES10 ||
			quiz.airSar === AirSAR.SORTIES20 ||
			quiz.airSar === AirSAR.SORTIES30) &&
			!input.airSARChecked &&
			`The cadet has done at least 10 sorties, the air sar ribbon must be checked`,
		quiz.distinguishedService &&
			!input.distinguished_serviceChecked &&
			`The cadet has recieved the distinguished service award, the distinguished service ribbon must be checked`,
		!quiz.distinguishedService &&
			input.distinguished_serviceChecked &&
			`The cadet has not recieved the distinguished service award, the distinguished service ribbon must not be checked`,
		quiz.exceptionalService &&
			!input.exceptional_serviceChecked &&
			`The cadet has recieved the exceptional service award, the exceptional service ribbon must be checked`,
		!quiz.exceptionalService &&
			input.exceptional_serviceChecked &&
			`The cadet has not recieved the exceptional service award, the exceptional service ribbon must not be checked`,
		quiz.meritoriousService &&
			!input.meritorious_serviceChecked &&
			`The cadet has recieved the meritorious service award, the meritorious service ribbon must be checked`,
		!quiz.meritoriousService &&
			input.meritorious_serviceChecked &&
			`The cadet has not recieved the meritorious service award, the meritorious service ribbon must not be checked`,
		quiz.capAchievement &&
			!input.cAP_AchievementChecked &&
			`The cadet has recieved the CAP Achievement award, the CAP Achievement ribbon must be checked`,
		!quiz.capAchievement &&
			input.cAP_AchievementChecked &&
			`The cadet has not recieved the CAP Achievement award, the CAP Achievement ribbon must not be checked`,
		quiz.lifesavingAward &&
			!input.lifesavingChecked &&
			`The cadet has recieved the lifesaving award, the lifesaving ribbon must be checked`,
		!quiz.lifesavingAward &&
			input.lifesavingChecked &&
			`The cadet has not recieved the lifesaving award, the lifesaving ribbon must not be checked`,
		quiz.commandersCommendation &&
			!input.commanders_CommendationChecked &&
			`The cadet has recieved the commander's commendation award, the commander's commendation ribbon must be checked`,
		!quiz.commandersCommendation &&
			input.commanders_CommendationChecked &&
			`The cadet has not recieved the commander's commendation award, the commander's commendation ribbon must not be checked`,
		quiz.natCommanderUnitCitation &&
			!input.national_Unit_CitationChecked &&
			`The cadet has recieved the National Commander's Unit Citation award, the National Commander's Unit Citation award must be checked`,
		!quiz.natCommanderUnitCitation &&
			input.national_Unit_CitationChecked &&
			`The cadet has not recieved the National Commander's Unit Citation award, the National Commander's Unit Citation award must not be checked`,
		quiz.unitCitation &&
			!input.unit_CitationChecked &&
			`The cadet has recieved the unit citation award, the unit citation ribbon must be checked`,
		!quiz.unitCitation &&
			input.unit_CitationChecked &&
			`The cadet has not recieved the unit citation award, the unit citation ribbon must not be checked`,
		quiz.findRibbon &&
			!input.findChecked &&
			`The cadet has recieved the find ribbon, the find ribbon must be checked`,
		!quiz.findRibbon &&
			input.findChecked &&
			`The cadet has not recieved the find ribbon, the find ribbon must not be checked`,
		quiz.disasterRelief &&
			!input.disasterReliefChecked &&
			`The cadet has recieved the disaster relief ribbon, the disaster relief ribbon must be checked`,
		!quiz.disasterRelief &&
			input.disasterReliefChecked &&
			`The cadet has not recieved the disaster relief ribbon, the disaster relief ribbon must not be checked`,
		quiz.afsaSquadronNCOofTheYear &&
			!input.aFSAChecked &&
			`The cadet has recieved the AFSA ribbon, the AFSA ribbon must be checked`,
		!quiz.afsaSquadronNCOofTheYear &&
			input.aFSAChecked &&
			`The cadet has not recieved the AFSA ribbon, the AFSA ribbon must not be checked`,
		quiz.vfwNCOofTheYear &&
			!input.vFW_NCOChecked &&
			`The cadet has recieved the VFW NCO ribbon, the VFW NCO ribbon must be checked`,
		!quiz.vfwNCOofTheYear &&
			input.vFW_NCOChecked &&
			`The cadet has not recieved the VFW NCO ribbon, the VFW NCO ribbon must not be checked`,
		input.vfwOfficer &&
			`The cadet is a Non commissioned officer and cannot earn the VFW officer award`,
		!input.curryChecked &&
			`The cadet has already earned the Curry ribbon, the Curry ribbon must be checked`,
		!input.arnoldChecked &&
			`The cadet has already earned the Arnold ribbon, the Arnold ribbon must be checked`,
		!input.feikChecked &&
			`The cadet has already earned the Feik ribbon, the Feik ribbon must be checked`,
		!input.rickenbackerChecked &&
			`The cadet has already earned the Rickenbacker ribbon, the Rickenbacker ribbon must be checked`,
		!input.wrightBrothersChecked &&
			`The cadet has already earned the Wright Brothers ribbon, the Wright Brothers ribbon must be checked`,
		quiz.afaRibbon &&
			!input.afaChecked &&
			`The cadet has recieved the AFA ribbon, the AFA ribbon must be checked`,
		!quiz.afaRibbon &&
			input.afaChecked &&
			`The cadet has not recieved the AFA ribbon, the AFA ribbon must not be checked`,
		hasAfoeaRibbon &&
			!input.AFOEAChecked &&
			`The cadet has recieved the AFOEA ribbon, the AFOEA ribbon must be checked`,
		!hasAfoeaRibbon &&
			input.AFOEAChecked &&
			`The cadet has not recieved the AFOEA ribbon, the AFOEA ribbon must not be checked`,

		quiz.activityAttendance.type === 'NONE' &&
			input.encampmentChecked &&
			`The cadet has not attended encampment and the encampment ribbon is checked`,
		quiz.activityAttendance.type === 'NONE' &&
			input.nCSAsChecked &&
			`The cadet has not attended encampment and the NCSA ribbon is checked`,
		quiz.activityAttendance.type === 'ATTENDED_ENCAMPMENT' &&
			!input.encampmentChecked &&
			`The cadet has attended encampment and the encampment ribbon is not checked`,
		quiz.activityAttendance.type === 'ATTENDED_ENCAMPMENT' &&
			quiz.activityAttendance.ncsas > 0 &&
			!input.nCSAsChecked &&
			`The cadet has attended at least 1 NCSA and the NCSA ribbon is not checked`,
	].filter((s): s is string => !!s);
}

function getBiography(quiz: Quiz): string {
	return [
		getGradeString(quiz.grade),
		getCACLevelString(quiz.cac),
		getColorGuardString(quiz.colorGuardCompetition),
		getAirSARString(quiz.airSar),
		getActivityAttendanceString(quiz.activityAttendance),
		getCommunityServiceString(quiz.communityService),
		quiz.cadetsRecruited !== 0
			? `This cadet has recruited ${quiz.cadetsRecruited} cadet${
					quiz.cadetsRecruited !== 0 ? 's' : ''
			  }.`
			: undefined,
		`This cadet has spent ${quiz.yearsInCAP} year${quiz.yearsInCAP !== 1 ? 's' : ''} in CAP.`,
		quiz.findRibbon ? 'This cadet found a search target during a distress mission.' : undefined,
		quiz.disasterRelief
			? 'This cadet has provided emergency services support during one presidential declared disaster.'
			: undefined,
		quiz.vfwNCOofTheYear
			? 'This cadet has recieved the VFW Award to Outstanding Cadet NCOs.'
			: undefined,
		quiz.afsaSquadronNCOofTheYear
			? "This cadet has recieved the Air Force Sergeant's Association Award to outstanding cadets."
			: undefined,
		quiz.unitCitation ? 'This cadets unit has recieved a Unit Citation.' : undefined,
		quiz.lifesavingAward ? 'This cadet has recieved a Lifesaving Ribbon.' : undefined,
		quiz.natCommanderUnitCitation
			? "This cadet's unit has recieved a National Commanders Unit Citation Award."
			: undefined,
		quiz.capAchievement ? 'This cadet has recieved a CAP Achievement Ribbon.' : undefined,
		quiz.commandersCommendation
			? "This cadet has recieved a Commander's Commendation ribbon."
			: undefined,
		quiz.meritoriousService
			? 'This cadet has recieved a Meritorious Service Award.'
			: undefined,
		quiz.exceptionalService
			? 'This cadet has recieved a Exceptional Service Award.'
			: undefined,
		quiz.distinguishedService
			? 'This cadet has recieved a Distinguished Service Award.'
			: undefined,
		quiz.silverMedalOfValor ? 'This cadet has recieved a Silver Medal of Valor.' : undefined,
		quiz.bronzeMedalOfValor ? 'This cadet has recieved a Bronze Medal of Valor.' : undefined,
		quiz.afaRibbon ? 'This cadet has recieved the AFA ribbon.' : undefined,
	]
		.filter(item => !!item)
		.join(' ');
}

const InsigniaRadio: FunctionComponent<{
	checked: boolean;
	name: InsigniaKeys;
	onCheck: (name: InsigniaKeys) => void;
}> = ({ checked, name, onCheck }) => (
	<div className={styles.inline}>
		<input
			type="radio"
			checked={checked}
			onChange={() => onCheck(name)}
			name="grade"
			id={name}
		/>
		<label htmlFor={name}>
			<img className={styles.insigniaImage} src={insigniaURLs[name]} alt={name} />
		</label>
	</div>
);

const RibbonCheckbox: FunctionComponent<{
	checked: boolean;
	name: RibbonKeys;
	onCheck: () => void;
}> = ({ checked, name, onCheck }) => (
	<div className={styles.inline}>
		<input type="checkbox" checked={checked} onChange={onCheck} name={name} id={name} />
		<label htmlFor={name}>
			<img className={styles.ribbonImage} src={ribbonURLs[name]} alt={name} />
		</label>
	</div>
);
interface QuizzerStateForInput {
	type: 'INPUT';
	quiz: Quiz;
	quizInput: QuizInput;
}

interface QuizzerStateForResults {
	type: 'RESULTS';
	quiz: Quiz;
	quizInput: QuizInput;
	errors: string[];
}

type QuizzerState = QuizzerStateForInput | QuizzerStateForResults;

const spanStyle: React.CSSProperties = {
	display: 'inline-block',
	margin: '3px',
};

export class Quizzer extends Page<PageProps, QuizzerState> {
	public state: QuizzerState = {
		type: 'INPUT',
		quiz: generateQuiz(),
		quizInput: getEmptyQuizInput(),
	};

	public render(): JSX.Element {
		const state = this.state;

		if (state.type === 'RESULTS') {
			return (
				<div>
					<h1>Here are your results</h1>
					<ul>
						{state.errors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
					{state.errors.length === 0 ? <div>You passed!</div> : null}
					<Button onClick={this.tryAgain} buttonType="primaryButton">
						Try again
					</Button>
					<span style={spanStyle} />
					<Button onClick={this.newQuiz} buttonType="primaryButton">
						New quiz
					</Button>
				</div>
			);
		}

		return (
			<div className={styles['quizzer-root']}>
				{process.env.NODE_ENV === 'development' ? (
					<Button onClick={this.newQuiz} buttonType="primaryButton">
						New quiz
					</Button>
				) : null}
				<div>{getBiography(state.quiz)}</div>
				<div>
					<InsigniaRadio
						name="lindberghInsigniaChecked"
						checked={
							state.quizInput.grade?.level === GradeLevel.LINDBERGH &&
							!state.quizInput.grade?.isFirstSergeant
						}
						onCheck={this.handleRadioChecked({
							level: GradeLevel.LINDBERGH,
							isFirstSergeant: false,
						})}
					/>
					<InsigniaRadio
						name="doolittleInsigniaChecked"
						checked={
							state.quizInput.grade?.level === GradeLevel.DOOLITTLE &&
							!state.quizInput.grade?.isFirstSergeant
						}
						onCheck={this.handleRadioChecked({
							level: GradeLevel.DOOLITTLE,
							isFirstSergeant: false,
						})}
					/>
					<InsigniaRadio
						name="chiefInsigniaChecked"
						checked={
							state.quizInput.grade?.level === GradeLevel.GODDARD &&
							!state.quizInput.grade?.isFirstSergeant
						}
						onCheck={this.handleRadioChecked({
							level: GradeLevel.GODDARD,
							isFirstSergeant: false,
						})}
					/>
					<br />
					<InsigniaRadio
						name="lindberghFsInsigniaChecked"
						checked={
							state.quizInput.grade?.level === GradeLevel.LINDBERGH &&
							state.quizInput.grade?.isFirstSergeant
						}
						onCheck={this.handleRadioChecked({
							level: GradeLevel.LINDBERGH,
							isFirstSergeant: true,
						})}
					/>
					<InsigniaRadio
						name="doolittleFsInsigniaChecked"
						checked={
							state.quizInput.grade?.level === GradeLevel.DOOLITTLE &&
							state.quizInput.grade?.isFirstSergeant
						}
						onCheck={this.handleRadioChecked({
							level: GradeLevel.DOOLITTLE,
							isFirstSergeant: true,
						})}
					/>
					<InsigniaRadio
						name="chiefFsInsigniaChecked"
						checked={
							state.quizInput.grade?.level === GradeLevel.GODDARD &&
							state.quizInput.grade?.isFirstSergeant
						}
						onCheck={this.handleRadioChecked({
							level: GradeLevel.GODDARD,
							isFirstSergeant: true,
						})}
					/>

					<br />
					<RibbonCheckbox
						name="AFOEAChecked"
						checked={state.quizInput.AFOEAChecked}
						onCheck={this.handleImageChecked('AFOEAChecked')}
					/>
					<RibbonCheckbox
						name="silver_MVChecked"
						checked={state.quizInput.silver_MVChecked}
						onCheck={this.handleImageChecked('silver_MVChecked')}
					/>
					<RibbonCheckbox
						name="bronze_MVChecked"
						checked={state.quizInput.bronze_MVChecked}
						onCheck={this.handleImageChecked('bronze_MVChecked')}
					/>
					<RibbonCheckbox
						name="distinguished_serviceChecked"
						checked={state.quizInput.distinguished_serviceChecked}
						onCheck={this.handleImageChecked('distinguished_serviceChecked')}
					/>
					<RibbonCheckbox
						name="exceptional_serviceChecked"
						checked={state.quizInput.exceptional_serviceChecked}
						onCheck={this.handleImageChecked('exceptional_serviceChecked')}
					/>
					<br />
					<RibbonCheckbox
						name="meritorious_serviceChecked"
						checked={state.quizInput.meritorious_serviceChecked}
						onCheck={this.handleImageChecked('meritorious_serviceChecked')}
					/>
					<RibbonCheckbox
						name="commanders_CommendationChecked"
						checked={state.quizInput.commanders_CommendationChecked}
						onCheck={this.handleImageChecked('commanders_CommendationChecked')}
					/>
					<RibbonCheckbox
						name="cAP_AchievementChecked"
						checked={state.quizInput.cAP_AchievementChecked}
						onCheck={this.handleImageChecked('cAP_AchievementChecked')}
					/>
					<RibbonCheckbox
						name="lifesavingChecked"
						checked={state.quizInput.lifesavingChecked}
						onCheck={this.handleImageChecked('lifesavingChecked')}
					/>
					<RibbonCheckbox
						name="national_Unit_CitationChecked"
						checked={state.quizInput.national_Unit_CitationChecked}
						onCheck={this.handleImageChecked('national_Unit_CitationChecked')}
					/>
					<br />
					<RibbonCheckbox
						name="unit_CitationChecked"
						checked={state.quizInput.unit_CitationChecked}
						onCheck={this.handleImageChecked('unit_CitationChecked')}
					/>
					<RibbonCheckbox
						name="armstrongChecked"
						checked={state.quizInput.armstrongChecked}
						onCheck={this.handleImageChecked('armstrongChecked')}
					/>
					<RibbonCheckbox
						name="goddardChecked"
						checked={state.quizInput.goddardChecked}
						onCheck={this.handleImageChecked('goddardChecked')}
					/>
					<RibbonCheckbox
						name="doolittleChecked"
						checked={state.quizInput.doolittleChecked}
						onCheck={this.handleImageChecked('doolittleChecked')}
					/>
					<RibbonCheckbox
						name="lindbergChecked"
						checked={state.quizInput.lindbergChecked}
						onCheck={this.handleImageChecked('lindbergChecked')}
					/>
					<br />
					<RibbonCheckbox
						name="rickenbackerChecked"
						checked={state.quizInput.rickenbackerChecked}
						onCheck={this.handleImageChecked('rickenbackerChecked')}
					/>
					<RibbonCheckbox
						name="wrightBrothersChecked"
						checked={state.quizInput.wrightBrothersChecked}
						onCheck={this.handleImageChecked('wrightBrothersChecked')}
					/>
					<RibbonCheckbox
						name="feikChecked"
						checked={state.quizInput.feikChecked}
						onCheck={this.handleImageChecked('feikChecked')}
					/>
					<RibbonCheckbox
						name="arnoldChecked"
						checked={state.quizInput.arnoldChecked}
						onCheck={this.handleImageChecked('arnoldChecked')}
					/>
					<RibbonCheckbox
						name="curryChecked"
						checked={state.quizInput.curryChecked}
						onCheck={this.handleImageChecked('curryChecked')}
					/>
					<br />
					<RibbonCheckbox
						name="afaChecked"
						checked={state.quizInput.afaChecked}
						onCheck={this.handleImageChecked('afaChecked')}
					/>
					<RibbonCheckbox
						name="aFSAChecked"
						checked={state.quizInput.aFSAChecked}
						onCheck={this.handleImageChecked('aFSAChecked')}
					/>
					<RibbonCheckbox
						name="vfwOfficer"
						checked={state.quizInput.vfwOfficer}
						onCheck={this.handleImageChecked('vfwOfficer')}
					/>
					<RibbonCheckbox
						name="vFW_NCOChecked"
						checked={state.quizInput.vFW_NCOChecked}
						onCheck={this.handleImageChecked('vFW_NCOChecked')}
					/>
					<RibbonCheckbox
						name="redService"
						checked={state.quizInput.redService}
						onCheck={this.handleImageChecked('redService')}
					/>
					<br />
					<RibbonCheckbox
						name="findChecked"
						checked={state.quizInput.findChecked}
						onCheck={this.handleImageChecked('findChecked')}
					/>
					<RibbonCheckbox
						name="airSARChecked"
						checked={state.quizInput.airSARChecked}
						onCheck={this.handleImageChecked('airSARChecked')}
					/>
					<RibbonCheckbox
						name="disasterReliefChecked"
						checked={state.quizInput.disasterReliefChecked}
						onCheck={this.handleImageChecked('disasterReliefChecked')}
					/>
					<RibbonCheckbox
						name="communityServiceChecked"
						checked={state.quizInput.communityServiceChecked}
						onCheck={this.handleImageChecked('communityServiceChecked')}
					/>
					<RibbonCheckbox
						name="iaceChecked"
						checked={state.quizInput.iaceChecked}
						onCheck={this.handleImageChecked('iaceChecked')}
					/>
					<br />
					<RibbonCheckbox
						name="colorGuardChecked"
						checked={state.quizInput.colorGuardChecked}
						onCheck={this.handleImageChecked('colorGuardChecked')}
					/>
					<RibbonCheckbox
						name="cACChecked"
						checked={state.quizInput.cACChecked}
						onCheck={this.handleImageChecked('cACChecked')}
					/>
					<RibbonCheckbox
						name="nCSAsChecked"
						checked={state.quizInput.nCSAsChecked}
						onCheck={this.handleImageChecked('nCSAsChecked')}
					/>
					<RibbonCheckbox
						name="encampmentChecked"
						checked={state.quizInput.encampmentChecked}
						onCheck={this.handleImageChecked('encampmentChecked')}
					/>
					<RibbonCheckbox
						name="recruitChecked"
						checked={state.quizInput.recruitChecked}
						onCheck={this.handleImageChecked('recruitChecked')}
					/>
				</div>
				<div>
					<Button onClick={this.formSubmit} buttonType="primaryButton">
						Submit
					</Button>
				</div>
			</div>
		);
	}

	private handleRadioChecked = (grade: Grade) => () => {
		this.setState(prevState =>
			prevState.type !== 'INPUT'
				? prevState
				: {
						type: 'INPUT',
						quizInput: {
							...prevState.quizInput,
							grade,
						},
						quiz: prevState.quiz,
				  },
		);
	};

	private handleImageChecked = (imageKey: keyof QuizInput) => () => {
		this.setState(prevState =>
			prevState.type !== 'INPUT'
				? prevState
				: {
						type: 'INPUT',
						quizInput: {
							...prevState.quizInput,
							[imageKey]: !prevState.quizInput[imageKey],
						},
						quiz: prevState.quiz,
				  },
		);
	};

	private formSubmit = (): void => {
		if (this.state.type !== 'INPUT') {
			return;
		}

		const errors = getErrorsForQuizInput(this.state.quiz, this.state.quizInput);

		this.setState({
			type: 'RESULTS',
			errors,
			quizInput: this.state.quizInput,
			quiz: this.state.quiz,
		});

		// this.state.quizInput
		// this.state.quiz
		// getErrorsForQuizInput
	};

	private tryAgain = (): void => {
		this.setState({
			type: 'INPUT',
			quizInput: this.state.quizInput,
			quiz: this.state.quiz,
		});
	};

	private newQuiz = (): void => {
		this.setState({
			type: 'INPUT',
			quizInput: getEmptyQuizInput(),
			quiz: generateQuiz(),
		});
	};
}
